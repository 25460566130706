import { CampanhaCashback } from '@/models/CampanhaCashback'

export function criarCampanhaCashback () {
	const campanha: CampanhaCashback = {
		id: '',
		descricao: '',
		dataInicio: new Date().toISOString().split('T')[0],
		dataFim: new Date().toISOString().split('T')[0],
		prioridade: 0,
		excecoes: [],
		aplicacoes: [],
		lojas: [],
	}
	return campanha
}