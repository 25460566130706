































import DialogoDeEdicaoDeCampanhaDeCashback from '@/components/cashback/DialogoDeEdicaoDeCampanhaDeCashback.vue';
import ListagemDeCashback from '@/components/cliente/ListagemDeCashback.vue';
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FindCashbackUseCase } from '@/usecases';
import axios, { CancelTokenSource } from 'axios';
import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { criarCampanhaCashback } from '@/shareds/campanha-cashback-shareds';
import { CampanhaCashback } from '@/models/CampanhaCashback';
import { dateToPtBrFormat } from '@/shareds/date/date-utils';


@Component({
	components: {
		ListagemDeCashback,
		DataTableDeCrud,
		DialogoDeEdicaoDeCampanhaDeCashback,
	},
})
export default class TelaDeCampanhaDeCashback extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeCampanhaDeCashback

	headers = [
		{ text: 'Descrição', value: 'descricao' },
		{ text: 'Data Inicio', value: 'dataInicio' },
		{ text: 'Data Fim', value: 'dataFim' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1

	carregando = false
	mostrar = false

	busca = ''

	editando = true

	campanhasCashback: CampanhaCashback[] = []
	campanhaCashback: CampanhaCashback | null = null

	findUseCase = new FindCashbackUseCase()
	cancelToken: CancelTokenSource | null = null

	mostrarDialogoDeEdicao = false

	created() {
		this.buscar()
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				busca: this.busca || undefined,
				size: this.paginacao.itemsPerPage,
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const page = await this.findUseCase.findCampanhas(params, axiosConfig)
			this.totalRegistros = page.totalElements
			this.campanhasCashback = page.content
			this.carregando = false
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
		}
	}
	
	abrirDialogoDeCriarCampanha() {
		this.editando = false
		const campanhaCashback = criarCampanhaCashback()
		this.dialogoDeEdicao.mostrar(campanhaCashback, true)
	}

	abrirDialogoDeCashback(indice: any) {
		this.campanhaCashback = this.campanhasCashback[indice]
		this.editando = true
		this.dialogoDeEdicao.mostrar(this.campanhaCashback, true)
	}

	get itensFormatados() {
		return this.campanhasCashback.map(campanha => ({
			...campanha,
			dataInicio: dateToPtBrFormat(campanha.dataInicio),
			dataFim: dateToPtBrFormat(campanha.dataFim),
		}))
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar

	@Watch('busca')
	onChangeBusca() {
		this.paginacao.page = 1
		this.buscar()
	}
}
