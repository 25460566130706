

























import { Vue, Component, Watch } from 'vue-property-decorator'
import BuscaDeLoja from './BuscaDeLoja.vue'
import { Loja } from '@/models'

@Component({
	components: {
		BuscaDeLoja,
	},
})
export default class DialogoDeBuscaDeLoja extends Vue {
	loja: Loja | null = null
	mostra = false

	@Watch('loja')
	onChangeLoja(loja: Loja | null){
		if (!loja) return
		this.$emit('input', loja)
		this.mostra = false
		this.$nextTick(() => {
			this.loja = null
		})
	}
}

