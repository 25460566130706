
































































































































































































































































































import { TipoDeOperacao } from '@/models/Recurso'
import { AplicacaoCashback, CampanhaCashback, ExcecaoCashback } from '@/models/CampanhaCashback'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import DatePicker from '../ui/DatePicker.vue'
import DataTableDeCrud from '../ui/DataTableDeCrud.vue'
import SeletorDeTipoDeCliente from '../tabeladepreco/SeletorDeTipoDeCliente.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { SaveCashbackUseCase } from '@/usecases'
import { Loja, LojaDoPdv, TipoDeCliente } from '@/models'
import SeletorDeLojas from '../loja/SeletorDeLojas.vue'
import DialogoDeBuscaDeLoja from '../loja/DialogoDeBuscaDeLoja.vue'

@Component({
	components: {
		DatePicker,
		DataTableDeCrud,
		SeletorDeTipoDeCliente,
		SeletorDeLojas,
		DialogoDeBuscaDeLoja,
	},
})
export default class DialogoDeEdicaoDeCampanhaDeCashback extends Vue {
	@Ref() form!: HTMLFormElement
	mostra = false
	salvando = false
	campanhaCashback: CampanhaCashback | null = null
	criandoCampanha = false
	tipoDeClienteSelecionado: TipoDeCliente | null = null
	mostrarAdicaoDeTipoDeCliente = false

	etapa = 1

	headersTipoDeCliente = [
		{ text: 'Tipo', value: 'nome' },
	]

	headersLojas = [
		{ text: 'Nome', value: 'nomeFantasia' },
		{ text: 'CNPJ', value: 'cnpj' },
	]

	tiposDeExcecaoFixo: any[] = []
	tiposDeAplicacaoFixo: any[] = []

	tipoDeAplicacao = ['PERCENTUAL_MAXIMO', 'DESCONTO_NO_ITEM', 'DESCONTO_NO_TOTAL']

	percentualMaximo = 0

	saveUseCase = new SaveCashbackUseCase()

	get tiposExcecao () {
		if (!this.campanhaCashback) return []
		
		const indexVendaPorCliente = this.tiposDeExcecaoFixo.findIndex(tipo => tipo.tipoEnum === 'VENDA_POR_CLIENTE')

		if (indexVendaPorCliente < 0) {
			this.tiposDeExcecaoFixo.push({
				nome: 'Venda por tipo de cliente',
				ativo: this.campanhaCashback.excecoes.filter(excecao => excecao.tipo === 'VENDA_POR_CLIENTE').length > 0,
				tipoEnum: 'VENDA_POR_CLIENTE',
			})
		}

		return this.tiposDeExcecaoFixo
	}
	
	get podeAlterarCampanha() {
		return this.podeRealizarOperacao('alterar')
	}

	get tiposDeCliente(): TipoDeCliente[] {
		if (!this.campanhaCashback) return []
		if (!this.campanhaCashback.excecoes) return []

		const excecoesDeTipoDeCliente = this.campanhaCashback.excecoes.filter(excecao => excecao.tipo === 'VENDA_POR_CLIENTE')

		if (excecoesDeTipoDeCliente.length > 0) {
			return excecoesDeTipoDeCliente[0].tiposDeCliente
		}

		return []
	}
	
	get tiposAplicacao() {
		if (!this.campanhaCashback) return []

		const indexPercentualMaximoFixo = this.tiposDeAplicacaoFixo.findIndex(tipo => tipo.tipoEnum === 'PERCENTUAL_MAXIMO')
		const indexDescontoNoItemFixo = this.tiposDeAplicacaoFixo.findIndex(tipo => tipo.tipoEnum === 'DESCONTO_NO_ITEM')
		const indexDescontoNoTotalFixo = this.tiposDeAplicacaoFixo.findIndex(tipo => tipo.tipoEnum === 'DESCONTO_NO_TOTAL')

		if (indexPercentualMaximoFixo < 0) {
			const indexPercentualMaximoCampanha = this.campanhaCashback.aplicacoes.findIndex(aplicacao => aplicacao.tipo === 'PERCENTUAL_MAXIMO')

			let percentualMaximo = 0
			let aplicarNoItemComDesconto = false

			if (indexPercentualMaximoCampanha > -1) {
				percentualMaximo = this.campanhaCashback.aplicacoes[indexPercentualMaximoCampanha].percentualMaximo
				aplicarNoItemComDesconto = this.campanhaCashback.aplicacoes[indexPercentualMaximoCampanha].aplicarNoItemComDesconto
			}

			this.tiposDeAplicacaoFixo.push({
				nome: 'Percentual máximo, em cima do total da venda, que pode ser aplicado com o cashback',
				ativo: indexPercentualMaximoCampanha > -1,
				tipoEnum: 'PERCENTUAL_MAXIMO',
				percentual: percentualMaximo,
				aplicarNoItemComDesconto: aplicarNoItemComDesconto,
			})
		}

		if (indexDescontoNoItemFixo < 0) {
			const indexDecontoNoItemCampanha = this.campanhaCashback.aplicacoes.findIndex(aplicacao => aplicacao.tipo === 'DESCONTO_NO_ITEM')

			let percentualMaximo = 0
			let aplicarNoItemComDesconto = false
			
			if (indexDecontoNoItemCampanha > -1) {
				percentualMaximo = this.campanhaCashback.aplicacoes[indexDecontoNoItemCampanha].percentualMaximo
				aplicarNoItemComDesconto = this.campanhaCashback.aplicacoes[indexDecontoNoItemCampanha].aplicarNoItemComDesconto
			}

			this.tiposDeAplicacaoFixo.push({
				nome: 'Permitir usar cashback caso exista desconto no item',
				ativo: indexDecontoNoItemCampanha > -1,
				tipoEnum: 'DESCONTO_NO_ITEM',
				percentual: percentualMaximo,
				aplicarNoItemComDesconto: aplicarNoItemComDesconto,
			})
		}

		if (indexDescontoNoTotalFixo < 0) {
			const indexDecontoNoTotalCampanha = this.campanhaCashback.aplicacoes.findIndex(aplicacao => aplicacao.tipo === 'DESCONTO_NO_TOTAL')

			let percentualMaximo = 0
			let aplicarNoItemComDesconto = false
			
			if (indexDecontoNoTotalCampanha > -1) {
				percentualMaximo = this.campanhaCashback.aplicacoes[indexDecontoNoTotalCampanha].percentualMaximo
				aplicarNoItemComDesconto = this.campanhaCashback.aplicacoes[indexDecontoNoTotalCampanha].aplicarNoItemComDesconto
			}

			this.tiposDeAplicacaoFixo.push({
				nome: 'Permitir usar cashback caso exista desconto no total',
				ativo: indexDecontoNoTotalCampanha > -1,
				tipoEnum: 'DESCONTO_NO_TOTAL',
				percentual: percentualMaximo,
				aplicarNoItemComDesconto: aplicarNoItemComDesconto,
			})
		}

		return this.tiposDeAplicacaoFixo
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return !'campanha-de-cashback' || UserLoginStore.temAutorizacao(montarOperacaoDeRecurso(operacao, 'campanha-de-cashback'))
	}

	esconder() {
		this.etapa = 1
		this.campanhaCashback = null
		this.tiposDeExcecaoFixo = []
		this.tiposDeAplicacaoFixo = []
		this.mostra = false
	}

	mostrar(campanhaCashback: CampanhaCashback | null, criarNovaCampanha: boolean) {
		if (!campanhaCashback) return
		this.campanhaCashback = campanhaCashback
		this.mostra = true
		this.criandoCampanha = criarNovaCampanha
	}

	removerTipoDeCliente(indice) {
		if (!this.campanhaCashback) return

		const excecaoTiposDeClienteIndex = this.campanhaCashback.excecoes.findIndex(excecaoTipoDeCliente => excecaoTipoDeCliente.tipo === 'VENDA_POR_CLIENTE')

		if (excecaoTiposDeClienteIndex > -1) {
			this.campanhaCashback.excecoes[excecaoTiposDeClienteIndex].tiposDeCliente.splice(indice, 1)
		}
	}

	removerLoja(indice) {
		this.campanhaCashback?.lojas.splice(indice, 1)
	}

	adicionarTipoDeCliente() {
		if (!this.campanhaCashback) return

		try {
			this.campanhaCashback.excecoes.forEach(excecao => {
				if (excecao.tipo === 'VENDA_POR_CLIENTE' && this.tipoDeClienteSelecionado) {
					if (excecao.tiposDeCliente.filter(tipo => tipo.id === this.tipoDeClienteSelecionado?.id).length > 0) {
						throw new Error('Tipo de cliente já foi adicionado')
					}
					
					excecao.tiposDeCliente.push(this.tipoDeClienteSelecionado)
				}
			})
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.fecharDialogoDeAdicaoDeTipoDeCliente()
		}
	}

	adicionarLoja(loja: Loja) {
		if (!this.campanhaCashback) return
		if (!loja) return

		try {
			const lojaFormatada = {
				id: loja.id,
				nomeFantasia: loja.nomeFantasia,
				cnpj: loja.cnpj,
			} as LojaDoPdv

			if (this.campanhaCashback.lojas.find(lojaDaCampanha => lojaDaCampanha.id === lojaFormatada.id)) {
				throw new Error('Loja já foi adicionada')
			}

			this.campanhaCashback.lojas.push(lojaFormatada)
		} catch(error) {
			AlertModule.setError(error)
		}
	}

	fecharDialogoDeAdicaoDeTipoDeCliente() {
		this.mostrarAdicaoDeTipoDeCliente = false
		this.tipoDeClienteSelecionado = null
	}

	@Watch('tiposDeExcecaoFixo', {deep: true})
	onChangeTiposDeExcecaoFixo() {
		this.tiposDeExcecaoFixo.forEach(tipo => {
			if (this.campanhaCashback && tipo.tipoEnum === 'VENDA_POR_CLIENTE') {
				if (tipo.ativo === false) {
					this.campanhaCashback.excecoes.forEach(excecao => {
						if (excecao.tipo === 'VENDA_POR_CLIENTE') {
							excecao.tiposDeCliente = []
						}
					})
				} else if (tipo.ativo === true && this.campanhaCashback.excecoes.length === 0) {
					this.campanhaCashback.excecoes.push({
						id: '',
						tipo: 'VENDA_POR_CLIENTE',
						tiposDeCliente: [],
					} as ExcecaoCashback)
				}
			}
		})
	}

	@Watch('tiposDeAplicacaoFixo', {deep: true})
	onChangeTiposDeAplicacaoFixo() {
		this.tiposDeAplicacaoFixo.forEach(tipo => {
			const tipoPreCadastrado = this.tipoDeAplicacao.find(tipoRequerido => tipoRequerido === tipo.tipoEnum)
			if (this.campanhaCashback && tipo.tipoEnum === tipoPreCadastrado) {

				const indexAplicacao = this.campanhaCashback.aplicacoes.findIndex(aplicacao => aplicacao.tipo === tipoPreCadastrado)

				if (!tipo.ativo) {
					if (indexAplicacao > -1) {
						this.campanhaCashback.aplicacoes.splice(indexAplicacao, 1)
					}
				} else if (tipo.ativo && indexAplicacao === -1) {
					this.campanhaCashback.aplicacoes.push({
						id: '',
						tipo: tipoPreCadastrado,
						percentualMaximo: tipo.percentual,
						aplicarNoItemComDesconto: tipo.aplicarNoItemComDesconto,
					} as AplicacaoCashback)
				} else if (tipo.ativo && indexAplicacao > -1) {

					if (tipo.percentual != 0) {
						this.campanhaCashback.aplicacoes[indexAplicacao].percentualMaximo = tipo.percentual
					}

					this.campanhaCashback.aplicacoes[indexAplicacao].aplicarNoItemComDesconto = tipo.aplicarNoItemComDesconto
				}
			}
		})
	}

	async salvar(campanhaCashback: CampanhaCashback | null) {
		if (!campanhaCashback) return

		try {
			this.salvando = true
			if (campanhaCashback.id === '') {
				const campanhaSalva = await this.saveUseCase.criar(campanhaCashback)

				this.$emit('incluirCampanhaNova', campanhaSalva)
			} else {
				const campanhaSalva = await this.saveUseCase.atualizar(campanhaCashback)

				this.$emit('atualizarCampanhaExistente', campanhaSalva)
			}
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
			this.esconder()
		}
	}
}
